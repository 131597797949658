input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
body, html{
    font-family: 'Lato', sans-serif !important;
}
.logout{
    background-color: #f07a26 !important;
    color: white !important;
}
.auth .brand-logo img {
    width: 99px;
}
.orangeButton{
    background-color: #f07a26 !important;
    color: #fff !important;
}
.greenButton{
    background-color: #3c8b42 !important;
    color: #fff !important;
}
.navbar .navbar-brand-wrapper .navbar-brand img{
    height: auto;
}
.navbar .navbar-brand-wrapper{
    width: 367px !important;
}
.tableBorder{
    border: 1px solid #b9b9b9;
}
.ml-99{
    margin-left: 99px;
}
.sidebar{
    background: #001b3b;
}
.sidebar .nav .nav-item .nav-link{
    color: #fff;
}
.ionicon-stroke-width {
    stroke-width: var(--ionicon-stroke-width, 44px);
}
.auth .brand-logo img{
    width: 222px;
}
.sidebar .nav .nav-item.active .nav-link .menu-title{
    color: #ffc107;
}
.table td, .table th {
    vertical-align: middle !important;
}
.swal2-styled{
    background-color: red !important;
}
.custImage{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px dashed #3c8b42;
    object-fit: cover;
}
.addBonusimg{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid #191c1b;
    object-fit: cover;
}
.badgeCharges{
    background-color: rgb(255, 211, 90);
    color: #191c1b;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}
.navbar{
    flex-wrap: nowrap !important;
}
.navbar .navbar-menu-wrapper{
    width: inherit !important;
}
.nav-profile{
    background-color: #00142d;
}
.nav-profile:hover{
    background-color: #00142d !important;
}
.orangeText{
    color: #f07a26;
    font-weight: 700;
}
.myWrapper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcome{
    font-size: 22px;
    font-weight: 500;
}
.youConnect{
    font-size:77px;
    font-weight: 700;
}
.portal{
    font-size: 22px;
    font-weight: 400;
}
.footer {
    background: #00142d;
    color: white;
}
.footer a{
    color: #ffb300;
    font-size: inherit;
    font-weight: 700;
}
table thead{
    background-color: rgb(0 123 255) !important;
    color: #fff !important;
}
.footerA{
    line-height: 23px;
    font-size: 15px;
}
.btn{
    cursor: pointer !important;
}
.updateAgent{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
/*---------------Tabular Data----------------*/

@media only screen and (max-width: 600px) {
    .content-wrapper {
      padding: 1rem;
    }
    .welcome{
        font-size: 33px;
    }
    .youConnect{
        font-size:55px;
    }
    .portal{
        font-size: 22px;
    }
  }
  .inputDigit .form-control{
    font-size: 22px !important;
    color: #3c8b42;
    font-weight: 700;
  }
  .bg-gray{
    background-color: #b9b9b9 !important;
    position: relative;
    z-index: 99;
  }
  table thead{
    background-color: #b9b9b9 !important;
  }
  .sidebar .nav {
    position: fixed;
    width: 260px;
}
@media (min-width: 992px)
{
.sidebar-icon-only .sidebar .hydrated {
    transform: translate(-97px, 0px);
}
}
.duplicateCust{
    max-height: 70vh;
    overflow-y: auto;
}
.duplicatePan{
    max-height: 70vh;
    overflow-y: auto;
}
.headFix{
    position: sticky;
    top: 0;
}
.footer {
    padding: 4px 1rem;
}
.nav-tabs, .nav-link{
    font-size: 15px !important;
    font-weight: 700;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #5b5b5b !important;
}
.content-wrapper {
    padding: 1rem;
}
.jcsb{
    justify-content: space-between;
}
.creditCount{
    background-color: #2d2d2d;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}
.imgBox{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textSuccess{
    color: #00dd33 !important;
}
.excelDrop{
    background-color: #e5e8ed;
    padding: 18px
}
@media only screen and (max-width: 992px){
.content-wrapper {
    margin-left: 0px;
}
}
/*---------------Customer----------------*/
.tableHeight{
    max-height: 588px;
    overflow-y: auto;
    position: relative;
    z-index: 0;
}
.stretch-card > .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.centerPic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.shadowBox{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
/*---------------Financer----------------*/